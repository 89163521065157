.check-input {
  display: none; }

.check-input-label {
  background-color: #f7f7f7;
  text-align: center;
  padding: 0 20px;
  line-height: 45px;
  border-radius: 3px;
  text-transform: capitalize;
  display: inline-block;
  cursor: pointer; }
  .check-input-label.invalid {
    color: #e95160; }
  .check-input-label.on {
    background-color: #ddd; }

.radio-group {
  display: flex; }
  .radio-group > * {
    flex: 1; }
    .radio-group > * + * {
      margin-left: 10px; }
