.select {
  position: relative; }
  .select.invalid :global .Dropdown-control {
    border-color: #e95160; }
  .select :global .Dropdown-control {
    overflow: hidden;
    background-color: transparent;
    display: flex;
    align-items: center;
    border-radius: 3px;
    height: 44px;
    cursor: default;
    outline: none;
    transition: all 200ms ease;
    border: 1px solid #d8d8d8;
    padding: 10px 25px 10px 10px;
    color: #696969; }
  .select :global .Dropdown-placeholder {
    white-space: nowrap; }
    .select :global .Dropdown-placeholder:not(.is-selected) {
      color: #9d9d9c; }
  .select :global .Dropdown-arrow-wrapper {
    position: absolute;
    right: 10px;
    top: 13px; }
  .select :global .Dropdown-menu {
    background-color: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    /* Track */
    /* Handle */ }
    .select :global .Dropdown-menu::-webkit-scrollbar {
      width: 5px; }
    .select :global .Dropdown-menu::-webkit-scrollbar-track {
      background: #d0d0d0; }
    .select :global .Dropdown-menu::-webkit-scrollbar-thumb {
      background: var(--body-primary-color)80%; }
    +
.select :global .Dropdown-menu::-webkit-scrollbar-thumb:hover {
      background: var(--body-primary-color); }
  .select :global .Dropdown-menu .Dropdown-group > .Dropdown-title {
    padding: 8px 10px;
    text-transform: capitalize; }
  .select :global .Dropdown-option {
    cursor: pointer;
    display: block;
    padding: 7px 15px 5px 15px;
    white-space: nowrap; }
  .select :global .Dropdown-option:hover {
    background-color: #e6e6e6; }
  .select :global .Dropdown-option.is-selected {
    background-color: #e6e6e6; }
  .select :global .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px; }
