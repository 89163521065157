.vacancy-body-map {
  height: 466px; }
  @media only screen and (max-width: 480px) {
    .vacancy-body-map {
      height: 266px; } }

.tooltip {
  text-align: left;
  margin: 10px;
  width: 380px; }
  .tooltip:after {
    content: '';
    clear: both;
    display: block; }

.tooltip-company {
  font-size: 10px; }

.tooltip-logo {
  width: 100px;
  margin: 0 10px 5px 0;
  float: left; }

.tooltip-badge {
  color: var(--body-secondary-color);
  font-size: 8px;
  border: 1px solid var(--body-secondary-color);
  border-radius: 3px;
  padding: 3px 5px;
  line-height: 1;
  white-space: nowrap;
  text-transform: uppercase;
  margin-right: 10px; }

.tooltip-title {
  font-family: Montserrat-Bold, sans-serif;
  font-size: 12px;
  color: var(--body-secondary-color); }

.marker-multiple-tooltip {
  position: relative; }

.marker-multiple-tooltip-nav {
  padding: 5px;
  font-size: 12px;
  border-bottom: 1px solid #cecece; }

.marker-multiple-tooltip-nav-label {
  display: inline-block;
  padding: 0 10px; }

.marker-multiple-tooltip-nav-btn {
  padding: 1px 7px 2px;
  border: 1px solid #cecece;
  background-color: transparent;
  cursor: pointer; }
