.layout {
  position: relative; }

.modal-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden; }
  .modal-filter__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    animation: show 0.5s;
    background-color: #fff; }
  .modal-filter__header {
    display: flex;
    padding: 0 20px;
    height: 60px; }
  .modal-filter__content {
    flex-grow: 1;
    display: flex;
    height: calc(100% - 60px); }
  .modal-filter__close {
    font-size: 30px;
    line-height: 20px;
    text-decoration: none; }

@keyframes show {
  from {
    transform: translateY(100%); }
  to {
    transform: translateY(0); } }
