.search-input-container {
  position: relative;
  color: #9d9d9c; }

.search-input {
  padding: 8px 35px !important;
  margin-top: 0 !important;
  height: 44px !important;
  font-size: 16px; }

.search-input-placeholder {
  margin-left: 35px;
  line-height: 44px; }

.search-input-icon {
  position: absolute;
  left: 10px;
  top: 13px; }

.search-input-remove {
  position: absolute;
  right: 10px;
  top: 13px; }
  .search-input-remove.hidden {
    display: none; }
  .search-input-remove:hover {
    color: #505050; }
