.kind-company {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 17px; }
  @media only screen and (max-width: 993px) {
    .kind-company {
      margin-bottom: 4px; } }

.tag-badge {
  display: inline-block;
  color: var(--body-secondary-color);
  font-size: 12px;
  border: 1px solid var(--body-secondary-color);
  border-radius: 3px;
  padding: 5px 10px;
  font-weight: 500;
  line-height: 1;
  margin-right: 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  text-transform: uppercase; }

.company {
  padding: 4px 4px 4px 0;
  display: block; }
