.popup-overlay {
  background: rgba(0, 0, 0, 0.9) !important;
  overflow: auto !important;
  z-index: 1000 !important;
  animation: changeBackground 0.3s ease-out;
  /* Track */
  /* Handle */ }
  @media only screen and (max-width: 768px) {
    .popup-overlay {
      display: block !important;
      background-color: #fff !important;
      animation: popupToTop 0.5s; } }
  .popup-overlay::-webkit-scrollbar {
    width: 5px; }
  .popup-overlay::-webkit-scrollbar-track {
    background: #d0d0d0; }
  .popup-overlay::-webkit-scrollbar-thumb {
    background: var(--body-primary-color)80%; }
  +
.popup-overlay::-webkit-scrollbar-thumb:hover {
    background: var(--body-primary-color); }

@keyframes popupToTop {
  from {
    top: 100%; }
  to {
    top: 0; } }

@keyframes changeBackground {
  from {
    background: rgba(0, 0, 0, 0); }
  to {
    background: rgba(0, 0, 0, 0.9); } }

.popup-content {
  width: 100% !important;
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 768px) {
    .popup-content {
      max-width: 100% !important;
      height: 100%; } }

.modal {
  width: 100%;
  animation: modalZoom 0.3s; }
  .modal > .header {
    color: #fff;
    font-size: 22px;
    line-height: 28px; }
  .modal > .close {
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin-bottom: 10px;
    color: #fff;
    text-decoration: none; }
    @media only screen and (max-width: 768px) {
      .modal > .close {
        position: absolute;
        right: 40px;
        top: 27px;
        color: #333333;
        z-index: 10; } }
    @media only screen and (max-width: 768px) {
      .modal > .close {
        position: absolute;
        right: 23px;
        top: 27px;
        color: #333333;
        z-index: 10; } }
  .modal > .content {
    clear: both; }
    @media only screen and (max-width: 768px) {
      .modal > .content {
        height: 100%;
        display: flex;
        align-items: center; } }
  @media only screen and (max-width: 768px) {
    .modal {
      height: 100%;
      animation: none; } }

.modal-left-show {
  margin: inherit !important; }
  .modal-left-show .modal {
    animation: modalLeftShow 0.3s; }
    .modal-left-show .modal > button {
      position: absolute;
      top: 30px;
      right: 40px;
      color: #525252; }
      @media only screen and (max-width: 480px) {
        .modal-left-show .modal > button {
          right: 12px;
          top: 20px; } }
    .modal-left-show .modal .content {
      height: 100%; }
      .modal-left-show .modal .content .dialog {
        height: 100%;
        border-radius: 0; }
        @media only screen and (max-width: 768px) {
          .modal-left-show .modal .content .dialog {
            width: 100%; } }
    @media only screen and (max-width: 768px) {
      .modal-left-show .modal {
        animation: none; } }

@keyframes modalZoom {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.05); }
  100% {
    transform: scale(1); } }

@keyframes modalLeftShow {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }
