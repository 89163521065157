.footer {
  padding: 50px 20px;
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (max-width: 993px) {
    .footer {
      justify-content: flex-start;
      padding: 40px 20px; } }

.footer-box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 30px; }
  .footer-box:last-child {
    padding-right: 0; }
  @media only screen and (max-width: 993px) {
    .footer-box {
      flex-grow: 0;
      min-width: calc(100% / 3); } }
  @media only screen and (max-width: 768px) {
    .footer-box {
      min-width: 50%; } }

.footer-title {
  color: #5da4da;
  min-height: 35px;
  font-size: 15px; }

.social-links__icons {
  width: 135px; }

.footer-link {
  display: flex;
  color: #f3f4f1;
  font-family: Montserrat-Regular, sans-serif;
  transition: 0.3s;
  cursor: pointer;
  margin-bottom: 20px; }
  .footer-link:hover {
    color: #e95160; }

.social-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .social-box__link {
    width: 35px;
    height: 35px;
    margin: 0 0 10px;
    border-radius: 50%;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: 0.3s;
    cursor: pointer; }
    .social-box__link:hover {
      opacity: 0.8; }
    .social-box__link--facebook-f {
      background-color: #347cd2; }
    .social-box__link--twitter {
      background-color: #1ec4ff; }
    .social-box__link--youtube {
      background-color: #ff0000; }
    .social-box__link--instagram {
      background-color: #ed1376; }
    .social-box__link--linkedin {
      background-color: #1881ba; }
    .social-box__link--xing {
      background-color: #046064; }
