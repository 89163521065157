/**
 * ENGINE
 * v0.2 | 20150615
 * License: none (public domain)
 */
*,
*:after,
*:before {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='checkbox'],
input[type='radio'] {
  display: none; }

select {
  text-indent: 0.01px;
  text-overflow: '';
  border: 0;
  border-radius: 0; }

select::-ms-expand {
  display: none; }

code,
pre {
  font-family: monospace, monospace;
  font-size: 1em; }

input,
a,
button {
  outline: none;
  text-decoration: none; }

.container {
  margin: 0 20px;
  box-sizing: border-box;
  height: 100%; }
  @media (max-width: 480px) {
    .container {
      margin: 0 10px; } }
  @media (min-width: 1270px) {
    .container {
      width: 100%;
      max-width: 1170px;
      margin: 0 auto; } }

.grey-text, .text-field input {
  color: #505050; }

.grey-text, .text-field input {
  color: #505050; }

.container {
  margin: 0 20px;
  box-sizing: border-box;
  height: 100%; }
  @media (max-width: 480px) {
    .container {
      margin: 0 10px; } }
  @media (min-width: 1270px) {
    .container {
      width: 100%;
      max-width: 1170px;
      margin: 0 auto; } }

@font-face {
  font-family: Montserrat-Regular;
  src: url("../fonts/Montserrat/Montserrat-Regular.otf") format("opentype"), url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"); }

@font-face {
  font-family: Montserrat-Light;
  src: url("../fonts/Montserrat/Montserrat-Light.otf") format("opentype"), url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype"); }

@font-face {
  font-family: Montserrat-Medium;
  src: url("../fonts/Montserrat/Montserrat-Medium.otf") format("opentype"), url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype"); }

@font-face {
  font-family: Montserrat-SemiBold;
  src: url("../fonts/Montserrat/Montserrat-SemiBold.otf") format("opentype"), url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: Montserrat-Bold;
  src: url("../fonts/Montserrat/Montserrat-Bold.otf") format("opentype"), url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"); }

@font-face {
  font-family: Montserrat-Bold-Italic;
  src: url("../fonts/Montserrat/Montserrat-BoldItalic.otf") format("opentype"), url("../fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url("../fonts/Montserrat/Montserrat-ExtraBold.otf") format("opentype"), url("../fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype"); }

#root {
  min-height: 100vh; }

.layout {
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column; }

.dropdown-position {
  order: -1; }

.fff {
  order: 2; }

.main {
  flex-grow: 1; }

@keyframes main-show {
  from {
    background-color: rgba(255, 255, 255, 0); }
  to {
    background-color: rgba(255, 255, 255, 0.8); } }

body {
  font-family: Montserrat-Medium, sans-serif;
  font-size: 16px;
  min-width: 320px; }

#root {
  min-width: 320px; }

* {
  box-sizing: border-box; }

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat-Bold', sans-serif;
  color: #183a64; }

h1 {
  font-size: 40px; }
  @media only screen and (max-width: 993px) {
    h1 {
      font-size: 30px; } }

h2 {
  font-size: 32px; }
  @media only screen and (max-width: 480px) {
    h2 {
      font-size: 20px; } }

h3 {
  font-size: 28px;
  word-break: break-word; }
  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 22px;
      word-break: break-word; } }
  @media only screen and (max-width: 480px) {
    h3 {
      word-break: break-all; } }

h4 {
  font-size: 24px; }
  @media only screen and (max-width: 993px) {
    h4 {
      font-size: 20px; } }

.paragraph {
  font-family: 'Montserrat-Regular', sans-serif;
  color: #183a64; }
  .paragraph--middle {
    font-size: 18px; }
  .paragraph--small {
    font-size: 16px; }

.logo {
  height: 42px;
  position: relative;
  z-index: 12; }
  .logo img {
    height: 100%; }

.header-link {
  color: var(--header-text-color);
  font-family: Montserrat-SemiBold, sans-serif;
  transition: 0.3s;
  text-transform: uppercase;
  cursor: pointer;
  flex-shrink: 0; }
  @media only screen and (max-width: 993px) {
    .header-link {
      color: #707070; } }
  .header-link a {
    color: inherit; }
  .header-link:hover {
    color: #161616; }

.black-box {
  padding: 40px;
  background-color: #161616; }

.button {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  height: 45px;
  background-color: transparent;
  transition: 0.3s;
  border: 1px solid transparent;
  cursor: pointer;
  font-family: Montserrat-Bold, sans-serif;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .button--full {
    min-width: 223px;
    height: 40px;
    background-color: var(--body-primary-color);
    color: #fff;
    font-size: inherit;
    justify-content: center;
    opacity: 1; }
    .button--full:hover, .button--full:active {
      opacity: 0.8; }
  .button-search {
    display: flex;
    background-color: var(--body-primary-color);
    color: #fff;
    font-size: inherit;
    min-width: 136px;
    justify-content: space-between; }
    .button-search svg use {
      stroke: #fff; }
    .button-search > span {
      display: flex;
      justify-content: space-between;
      width: 100%; }
    .button-search__label {
      margin-right: 20px;
      display: flex;
      align-items: center; }
      .button-search__label--right {
        margin-right: 0;
        margin-left: 20px; }
    .button-search div {
      display: flex;
      align-items: center; }
    .button-search:hover, .button-search:active {
      opacity: 0.8; }
  .button-arrow {
    display: flex;
    background-color: #f3f3f1;
    color: #e95160;
    font-size: inherit;
    min-width: 136px;
    justify-content: space-between; }
    .button-arrow__label {
      margin-right: 20px; }
      .button-arrow__label--right {
        margin-right: 0;
        margin-left: 20px; }
    .button-arrow:hover, .button-arrow:active {
      background-color: #fff; }
  .button-save {
    margin-top: 10px;
    height: 50px;
    width: 100%; }
    .button-save:disabled {
      cursor: not-allowed; }
  .button-transparent {
    color: var(--body-primary-color);
    background-color: transparent;
    border-color: var(--body-primary-color);
    transition: 0.3s;
    box-shadow: none;
    text-transform: uppercase;
    height: 39px; }
    .button-transparent:hover {
      background-color: var(--body-primary-color);
      color: #fff; }

.full-width {
  width: 100%; }

.text-field {
  width: 100%;
  font-family: Montserrat-Medium, sans-serif;
  -ms-text-align-last: right;
  position: relative; }
  .text-field ::-webkit-scrollbar {
    width: 5px; }
  .text-field ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .text-field ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey; }
  .text-field ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0; }
  .text-field > span {
    color: #333333;
    font-size: 12px;
    font-family: Montserrat-Medium, sans-serif;
    padding-left: 9px;
    display: block;
    margin-bottom: 6px;
    min-height: 15px; }
  .text-field input {
    width: 100%;
    height: 48px;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    font-size: inherit;
    padding: 0 12px;
    display: flex;
    align-items: center;
    transition: 0.3s; }
    .text-field input:focus {
      border: 1px solid #183a64; }
    .text-field input:disabled {
      border: 1px solid #e6e6e6;
      background-color: #e6e6e6;
      color: #a0a0a0; }
  .text-field--error input {
    border: 1px solid #ff2f2e;
    background-color: #fff2f2; }
  .text-field--error > span {
    color: #ff2f2e; }
  .text-field--error .react-selectize.root-node.default.simple-select .react-selectize-control {
    border: 1px solid #ff2f2e;
    background: #fff2f2 !important; }
  .text-field--success input {
    border: 1px solid #17d428; }
  .text-field--success > span {
    color: #17d428; }
  .text-field--success .react-selectize.root-node.default.simple-select .react-selectize-control {
    border: 1px solid #17d428;
    background: #fff !important; }
  .text-field__material {
    position: relative; }
    .text-field__material span {
      position: absolute;
      top: 18px;
      left: 5px;
      font-size: 14px;
      transition: 0.3s;
      z-index: 1;
      color: #a0a0a0; }
    .text-field__material .text-field__has-value {
      background-color: #fff;
      top: -7px;
      left: 10px;
      font-size: 12px;
      padding: 3px 5px;
      z-index: 3;
      color: #183a64; }
    .text-field__material input {
      position: relative;
      z-index: 2; }
    .text-field__material input::placeholder {
      visibility: hidden; }

.select-field {
  width: 100%;
  font-family: Montserrat-Medium, sans-serif; }
  .select-field ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0; }
  .select-field > span {
    color: #333333;
    font-size: 12px;
    font-family: Montserrat-Medium, sans-serif;
    padding-left: 9px;
    display: block;
    margin-bottom: 6px;
    min-height: 15px; }
  .select-field .react-selectize.root-node {
    position: relative;
    width: 100%; }
  .select-field .react-selectize.default.root-node .react-selectize-control {
    font-family: Montserrat-Medium, sans-serif;
    background-color: #fff;
    border: 1px solid;
    border-color: #e6e6e6;
    border-radius: 4px;
    font-size: 1em;
    min-height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .select-field .react-selectize.default.root-node.multi-select.open .react-selectize-control {
    border-color: #183a64; }
  .select-field .react-selectize.default.root-node .react-selectize-control .react-selectize-search-field-and-selected-values {
    padding: 5px; }
  .select-field .multi-select.react-selectize.default.root-node .simple-value {
    background-color: rgba(12, 199, 255, 0.06);
    border: 1px solid rgba(255, 0, 0, 0.07);
    border-radius: 2px;
    color: #505050; }
  .select-field .react-selectize.dropdown-menu.default {
    font-family: Montserrat-Regular, sans-serif;
    border: 1px solid #e6e6e6; }
  .select-field .react-selectize.multi-select ::-webkit-scrollbar {
    width: 5px; }
  .select-field .react-selectize.multi-select ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .select-field .react-selectize.multi-select ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey; }

.slick-slider .slick-button-prev,
.slick-slider .slick-button-next {
  width: 30px;
  height: 30px;
  color: #d8d8d8;
  position: absolute;
  z-index: 100;
  top: 50%;
  cursor: pointer; }

.slick-slider .slick-button-prev {
  left: 20px; }
  @media only screen and (max-width: 993px) {
    .slick-slider .slick-button-prev {
      left: 0; } }

.slick-dots {
  bottom: -50px; }

.slick-slider .slick-button-next {
  right: 20px; }
  @media only screen and (max-width: 993px) {
    .slick-slider .slick-button-next {
      right: 0; } }

.vjs-has-started:not(.vjs-paused) + div {
  display: none; }

img:after {
  content: '';
  background: #f1f2f3 url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNDNweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgNDMgMjYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQzIDI2IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBmaWxsPSIjQzlDRUQxIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3ICAgICIgZD0iTTQuNSw5QzcsOSw5LDcsOSw0LjVTNywwLDQuNSwwUzAsMiwwLDQuNVMyLDksNC41LDl6Ii8+PHBvbHlnb24gZmlsbD0iI0M5Q0VEMSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAgICAiIHBvaW50cz0iMjgsNiAyMSwxNyAxMywxMSA0LDI2IDQzLDI2ICIvPjwvc3ZnPg==) center no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100; }

.slick-slider img {
  width: 100%; }

.slick-list {
  height: auto !important; }

img {
  position: relative; }

.grey-text, .text-field input {
  color: #505050; }

:root {
  --body-border-color: #e6e6e6;
  --body-primary-color: #e95160;
  --body-secondary-color: #183a64;
  --button-primary-text-color: #fff;
  --button-primary-bg-color: #4d2b9f;
  --button-secondary-text-color: #fff;
  --button-secondary-bg-color: #cecece;
  --header-text-color: #707070;
  --header-bg-color: #312b3a;
  --header-bg-image: url('../img/header-background.png'); }
