.btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 6px 20px;
  border-radius: 5px;
  color: #fff;
  letter-spacing: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus {
    outline: 0; }
  .btn:disabled {
    opacity: 0.5; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn .icon {
    margin-left: 15px; }
    .btn .icon.icon-left {
      margin-left: 0;
      margin-right: 15px; }

.btn {
  position: relative; }
  .btn .btn-loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto; }
  .btn .btn-content-loading {
    opacity: 0.1; }

.btn-primary {
  background-color: var(--button-primary-bg-color);
  border-color: var(--button-primary-bg-color);
  color: var(--button-primary-text-color);
  text-transform: uppercase; }

.btn-secondary {
  background-color: var(--button-secondary-bg-color);
  border-color: var(--button-secondary-bg-color);
  color: var(--button-secondary-text-color);
  text-transform: uppercase; }

.btn-block {
  display: block;
  width: 100%; }
