.autosuggest-wrapper {
  position: relative;
  margin: 4px 0; }

.container {
  position: relative; }

.suggestionsContainer {
  display: none; }
  .suggestionsContainer.relative-container {
    position: relative;
    top: 0; }

.input {
  padding: 9px 10px 7px 40px;
  border-radius: 5px; }

.autosuggest-icon {
  position: absolute;
  top: 14px;
  left: 10px;
  z-index: 1; }

.autosuggest-remove {
  color: #9d9d9c;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 1; }

.suggestionsContainerOpen {
  display: block;
  position: absolute;
  top: 35px;
  width: 100%;
  border: 1px solid #cecece;
  background-color: #fff;
  border-radius: 5px;
  z-index: 2;
  max-height: 200px;
  overflow-y: auto; }
  .suggestionsContainerOpen::-webkit-scrollbar {
    width: 4px; }
  .suggestionsContainerOpen::-webkit-scrollbar-track {
    margin: 2px; }

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.suggestion {
  cursor: pointer;
  padding: 10px 20px; }

[class*='AutoSuggest_suggestionsContainerOpen'] {
  font-size: 16px; }
  [class*='AutoSuggest_suggestionsContainerOpen'] li {
    padding: 8px 4px;
    font-family: Montserrat-Regular, sans-serif; }

[class*='AutoSuggest_suggestionsContainer'] {
  top: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #e6e6e6;
  border-top: none;
  color: #505050;
  transition: 0.3s; }

.suggestionHighlighted {
  background-color: rgba(12, 199, 255, 0.06); }
