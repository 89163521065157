.search-box {
  color: #656565;
  width: 100%;
  position: relative; }
  @media (min-width: 0) and (max-width: 767px) {
    .search-box {
      width: 100%; } }

.search-filter {
  width: 100%;
  height: 51px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  transition: 0.3s;
  position: relative;
  font-size: 18px; }
  @media only screen and (max-width: 480px) {
    .search-filter {
      box-shadow: none;
      border-color: 1px solid #183a64; } }
  .search-filter:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(26, 26, 29, 0.08); }
