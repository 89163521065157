.filter-panel-group {
  margin-top: 10px; }
  .filter-panel-group label {
    font-weight: 500;
    font-size: 14px; }

.filter-panel-buttons {
  display: flex;
  justify-content: flex-end; }

.filter-panel-button {
  margin-top: 20px; }
  @media (min-width: 0) and (max-width: 479px) {
    .filter-panel-button {
      width: 100%; } }
