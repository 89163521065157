.city-tag-duration {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  clear: both;
  margin-top: 7px; }
  @media only screen and (max-width: 993px) {
    .city-tag-duration {
      margin-top: 0; } }
  .city-tag-duration > li {
    padding: 5px 0;
    margin-right: 20px;
    display: flex;
    align-items: center; }
    .city-tag-duration > li > div {
      margin-right: 10px; }
    .city-tag-duration > li div {
      display: flex;
      align-items: center;
      justify-content: center; }
  .city-tag-duration [data-src*='location'] use {
    stroke: transparent; }
