.adp {
  display: flex;
  box-sizing: border-box;
  flex-flow: column;
  position: fixed;
  z-index: 99999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 20px; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .adp {
      padding: 40px 20px;
      width: 325px; } }
  @media (min-width: 0) and (max-width: 767px) {
    .adp {
      padding: 40px 20px;
      width: 325px; } }

.adp-img {
  display: flex;
  margin: 0 auto; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .adp-img {
      width: 75%; } }
  @media (min-width: 0) and (max-width: 767px) {
    .adp-img {
      width: 75%; } }

.adp h3 {
  margin: 0;
  padding: 40px 0 30px 0;
  font-size: 40px;
  font-weight: bold;
  color: #090808;
  line-height: normal; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .adp h3 {
      font-size: 24px;
      padding: 40px 0 20px 0; } }
  @media (min-width: 0) and (max-width: 767px) {
    .adp h3 {
      font-size: 24px;
      padding: 40px 0 20px 0; } }

.adp p {
  flex-grow: 1;
  font-size: 20px;
  width: 400px;
  font-weight: 200;
  margin-bottom: 50px; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .adp p {
      font-size: 16px;
      margin-bottom: 20px;
      width: 100%; } }
  @media (min-width: 0) and (max-width: 767px) {
    .adp p {
      font-size: 16px;
      margin-bottom: 20px;
      width: 100%; } }

.adp button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 230px;
  height: 55px;
  text-decoration: none;
  background-color: #0900ed;
  padding: 10px 18px;
  box-sizing: border-box;
  color: #ffffff;
  font-weight: 400;
  border-radius: 8px;
  margin: 0 auto;
  border: none;
  cursor: pointer; }

.adp button:hover {
  background-color: rgba(9, 0, 237, 0.8); }

.adp-underlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99998; }
