.vacancy-header {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--body-border-color);
  border-radius: 5px; }
  @media only screen and (max-width: 768px) {
    .vacancy-header {
      display: block; } }

.vacancy-header-logo {
  padding: 10px;
  border-color: var(--body-border-color);
  border-style: solid;
  border-right-width: 1px;
  display: flex;
  align-items: center;
  width: 116px;
  flex-shrink: 0; }
  .vacancy-header-logo > img {
    height: 100%;
    width: 100%;
    object-fit: contain; }
  @media only screen and (max-width: 768px) {
    .vacancy-header-logo {
      width: 103px;
      height: 103px;
      border-bottom-width: 1px;
      float: left;
      margin-right: 10px;
      margin-bottom: 5px; } }

.vacancy-header-content {
  padding: 15px 30px;
  font-family: Montserrat-Medium, sans-serif; }
  @media only screen and (max-width: 993px) {
    .vacancy-header-content {
      font-size: 14px; } }
  @media only screen and (max-width: 768px) {
    .vacancy-header-content {
      padding: 12px; } }
  @media only screen and (max-width: 480px) {
    .vacancy-header-content {
      padding: 12px; } }
  .vacancy-header-content h3 {
    font-size: 24px;
    margin-bottom: 12px; }
    .vacancy-header-content h3 + ul {
      margin-bottom: 0; }
      @media only screen and (max-width: 993px) {
        .vacancy-header-content h3 + ul {
          margin-bottom: 5px; } }
    @media only screen and (max-width: 993px) {
      .vacancy-header-content h3 {
        font-size: 22px; } }
    @media only screen and (max-width: 480px) {
      .vacancy-header-content h3 {
        min-height: 100px; } }

.phone-visible {
  display: none; }
  @media only screen and (max-width: 768px) {
    .phone-visible {
      display: inline-block; } }

.apply-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  padding-right: 40px; }
  @media only screen and (max-width: 993px) {
    .apply-wrapper {
      padding-right: 20px; } }
  @media only screen and (max-width: 768px) {
    .apply-wrapper {
      display: flex;
      padding: 12px; } }
  @media only screen and (max-width: 480px) {
    .apply-wrapper {
      margin: 0;
      display: block; } }
