.vacancy {
  background-color: #fff;
  color: #696969;
  font-family: Montserrat-Medium, sans-serif;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: 0.2s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); }
  .vacancy:hover {
    box-shadow: 0 2px 4px rgba(34, 74, 94, 0.04), 0 4px 8px rgba(34, 74, 94, 0.04), 0 8px 16px rgba(34, 74, 94, 0.04), 0 16px 24px rgba(34, 74, 94, 0.08); }
  .vacancy:first-child > div {
    margin-top: 0; }
  @media only screen and (max-width: 768px) {
    .vacancy {
      margin-bottom: 12px; } }
