.password-form-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center; }

.password-form {
  flex-basis: 350px; }
  .password-form form {
    padding: 40px; }

.password-form-btn {
  width: 100%; }
