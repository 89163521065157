.profile-label {
  margin-right: 20px;
  cursor: pointer;
  outline: none; }

.profile-menu {
  font-size: 16px;
  letter-spacing: normal;
  padding: 0;
  margin: 0; }

.profile-menu li {
  display: block;
  margin: 0;
  padding: 5px 12px;
  text-align: left; }
  .profile-menu li + li {
    border-top: 1px solid #cecece; }
  .profile-menu li.profile-item-btn {
    cursor: pointer; }
    .profile-menu li.profile-item-btn:hover {
      background-color: #ebebf5; }
  .profile-menu li .icon {
    margin-right: 5px; }
