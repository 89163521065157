.tippy-tooltip.primary-theme {
  background-color: var(--body-primary-color);
  color: #fff;
  font-family: Montserrat-Medium, sans-serif;
  padding: 10px;
  border-radius: 3px;
  line-height: 20px;
  max-width: 200px !important;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset; }

.tippy-popper[x-placement^='top'] .tippy-tooltip.primary-theme .tippy-arrow {
  border-top-color: var(--body-primary-color); }

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.primary-theme .tippy-arrow {
  border-bottom-color: var(--body-primary-color); }

.tippy-popper[x-placement^='left'] .tippy-tooltip.primary-theme .tippy-arrow {
  border-left-color: var(--body-primary-color); }

.tippy-popper[x-placement^='right'] .tippy-tooltip.primary-theme .tippy-arrow {
  border-right-color: var(--body-primary-color); }

/*profile theme*/
.tippy-tooltip.light-theme {
  background-color: #fff;
  border: 1px solid #d8d8d8;
  color: #505050;
  padding: 0;
  font-family: Montserrat-Medium, sans-serif;
  border-radius: 3px;
  line-height: 20px;
  max-width: 200px;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset; }
  .tippy-tooltip.light-theme .tippy-arrow {
    transform-style: preserve-3d; }
    .tippy-tooltip.light-theme .tippy-arrow::after {
      content: '';
      position: absolute;
      left: -8px;
      transform: translateZ(-1px);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent; }

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-theme .tippy-arrow {
  border-bottom-color: #fff; }
  .tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-theme .tippy-arrow::after {
    bottom: -7px;
    border-bottom: 8px solid #d8d8d8; }

.tippy-popper[x-placement^='top'] .tippy-tooltip.light-theme .tippy-arrow {
  border-top-color: #fff; }
  .tippy-popper[x-placement^='top'] .tippy-tooltip.light-theme .tippy-arrow::after {
    top: -7px;
    border-top: 8px solid #d8d8d8; }

/*light theme*/
