.city-text-input {
  margin-left: 15px;
  max-width: 250px; }
  @media only screen and (max-width: 480px) {
    .city-text-input {
      display: none; } }

.icon-location {
  width: 20px;
  top: 13px; }
