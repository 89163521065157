.main {
  height: calc(100vh - 20px);
  min-width: 150px;
  margin-right: 4px;
  margin-left: -16px;
  padding: 0 16px;
  overflow: scroll;
  position: sticky;
  top: 20px; }
  .main.down-sticky {
    height: calc(100vh - 100px);
    top: 100px; }
  @media only screen and (max-width: 480px) {
    .main {
      display: none; } }

.filter-container {
  background: #fff;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  transition: 0.2s; }
  .filter-container:hover {
    box-shadow: 0 2px 4px rgba(34, 74, 94, 0.04), 0 4px 8px rgba(34, 74, 94, 0.04), 0 8px 16px rgba(34, 74, 94, 0.04), 0 16px 24px rgba(34, 74, 94, 0.08); }

.collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }
  .collapse-header-img {
    height: 20px; }

.collapse-content {
  padding-top: 20px;
  font-size: 12px; }

.collapse-button {
  display: flex;
  justify-content: center;
  width: 10px;
  font-size: 12px; }
  .collapse-button.white {
    color: #fff; }

.collapse-submit-button {
  margin: 0 auto;
  letter-spacing: 0;
  height: 38px !important;
  background: #fff !important;
  border-radius: 10px !important; }
  .collapse-submit-button:hover, .collapse-submit-button:active {
    opacity: 1 !important; }

.submit-button {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.collapse-input-field {
  font-size: 12px; }

.collapse-input {
  height: 38px !important;
  background: #fff;
  border-radius: 10px !important; }

.multichannel-header {
  line-height: 22px; }

.multichannel-collapse {
  margin: 10px;
  padding: 10px;
  border-radius: 10px; }

.multichannel-content-header {
  color: #fff;
  padding-bottom: 20px;
  line-height: 16px; }

.multichannel-privacy-policy {
  text-align: center;
  margin-top: 10px; }
  .multichannel-privacy-policy a {
    color: #fff; }

.countdown {
  color: #fff;
  padding: 20px;
  text-align: center; }
  .countdown .weiter {
    opacity: 0.5; }
  .countdown-timer {
    font-size: 40px;
    font-weight: bold; }

.whatsapp-button {
  color: #46c655 !important; }

.telegram-button {
  color: #1e96c8 !important; }

.sms-button {
  color: #455a64 !important; }

.discord-button {
  color: #7289d9 !important; }

.email-button {
  color: #1b66b3 !important; }

.whatsapp-channel {
  background: #46c655; }

.telegram-channel {
  background: #1e96c8; }

.sms-channel {
  background: #455a64; }

.discord-channel {
  background: #7289d9; }

.email-channel {
  background: #1b66b3; }
