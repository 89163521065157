.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  align-items: center;
  margin-top: 40px;
  font-family: Montserrat-Medium, sans-serif;
  flex-wrap: wrap; }

.page-item:first-child .page-link {
  margin-left: 0;
  margin-right: 10px;
  color: #b5b5b5;
  border-radius: 50%;
  padding: 3px 11px;
  transition: 0.3s; }
  .page-item:first-child .page-link:hover {
    color: var(--body-primary-color); }

.page-item:last-child .page-link {
  margin-left: 10px;
  color: #b5b5b5;
  border-radius: 50%;
  padding: 3px 11px;
  transition: 0.3s; }
  .page-item:last-child .page-link:hover {
    color: var(--body-primary-color); }

.page-item.active .page-link {
  background-color: var(--body-primary-color);
  z-index: 2;
  color: var(--button-primary-text-color); }

.page-link {
  position: relative;
  display: block;
  min-width: 30px;
  text-align: center;
  margin: 0 2px;
  padding: 5px 7px;
  line-height: 1.25;
  color: #b5b5b5;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.3s; }
  .page-link:hover {
    color: var(--body-primary-color); }
