.photos-grid {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: 100%; }
  @media only screen and (max-width: 480px) {
    .photos-grid {
      display: block; } }
  .photos-grid > *:nth-child(1) {
    width: 100%; }
  .photos-grid > *:nth-child(2) {
    margin-left: 20px;
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 768px) {
      .photos-grid > *:nth-child(2) {
        margin-left: 10px; } }
  .photos-grid .photos-grid-second-coll {
    display: flex;
    flex-direction: column;
    width: 30%; }
    @media only screen and (max-width: 480px) {
      .photos-grid .photos-grid-second-coll {
        width: 100%;
        flex-direction: row;
        margin: 0;
        margin-top: 10px; } }
    @media only screen and (max-width: 993px) {
      .photos-grid .photos-grid-second-coll > *:last-child {
        margin-right: 0; } }
    @media only screen and (max-width: 768px) {
      .photos-grid .photos-grid-second-coll > * {
        margin-right: 10px; } }
    @media only screen and (max-width: 480px) {
      .photos-grid .photos-grid-second-coll > *:nth-child(2) {
        margin-right: 0; } }
    @media only screen and (max-width: 480px) {
      .photos-grid .photos-grid-second-coll > *:nth-child(3) {
        display: none; } }
    .photos-grid .photos-grid-second-coll .photos-item-image {
      margin-left: 0;
      margin-bottom: 20px; }
      .photos-grid .photos-grid-second-coll .photos-item-image:last-child {
        margin-bottom: 0; }
      @media only screen and (max-width: 768px) {
        .photos-grid .photos-grid-second-coll .photos-item-image {
          margin: 0;
          margin-bottom: 10px; } }
      @media only screen and (max-width: 480px) {
        .photos-grid .photos-grid-second-coll .photos-item-image {
          margin: 0;
          margin-bottom: 0; }
          .photos-grid .photos-grid-second-coll .photos-item-image:first-child {
            margin-right: 10px; } }
    .photos-grid .photos-grid-second-coll.has-videos {
      flex-direction: row; }
      @media only screen and (max-width: 768px) {
        .photos-grid .photos-grid-second-coll.has-videos {
          flex-direction: column; } }
      @media only screen and (max-width: 480px) {
        .photos-grid .photos-grid-second-coll.has-videos {
          flex-direction: row; } }
      @media only screen and (max-width: 768px) {
        .photos-grid .photos-grid-second-coll.has-videos .photos-item-image {
          margin-bottom: 10px; }
          .photos-grid .photos-grid-second-coll.has-videos .photos-item-image:last-child {
            margin-bottom: 0; } }
      @media only screen and (max-width: 480px) {
        .photos-grid .photos-grid-second-coll.has-videos .photos-item-image {
          margin-bottom: 0; } }
      .photos-grid .photos-grid-second-coll.has-videos > * {
        margin-bottom: 0; }
        .photos-grid .photos-grid-second-coll.has-videos > * + * {
          margin-top: 0;
          margin-left: 20px; }
          @media only screen and (max-width: 993px) {
            .photos-grid .photos-grid-second-coll.has-videos > * + * {
              margin-left: 10px; } }
          @media only screen and (max-width: 768px) {
            .photos-grid .photos-grid-second-coll.has-videos > * + * {
              margin-left: 0; } }
          @media only screen and (max-width: 480px) {
            .photos-grid .photos-grid-second-coll.has-videos > * + * {
              margin-top: 0; } }
        @media only screen and (max-width: 480px) {
          .photos-grid .photos-grid-second-coll.has-videos > *:nth-child(3) {
            display: none; } }
  .photos-grid.full-width {
    flex-direction: column; }
  .photos-grid.has-videos {
    flex-direction: column;
    width: 55%; }
    .photos-grid.has-videos.single-photo {
      width: 62.3%; }
      @media only screen and (max-width: 480px) {
        .photos-grid.has-videos.single-photo {
          width: 100%; } }
    .photos-grid.has-videos .photos-grid-second-coll {
      width: 100%; }
      .photos-grid.has-videos .photos-grid-second-coll .photos-item-image {
        width: 32%; }
        @media only screen and (max-width: 768px) {
          .photos-grid.has-videos .photos-grid-second-coll .photos-item-image {
            width: 100%; } }
        @media only screen and (max-width: 480px) {
          .photos-grid.has-videos .photos-grid-second-coll .photos-item-image {
            width: 50%; } }
    @media only screen and (max-width: 993px) {
      .photos-grid.has-videos {
        width: 55.4%; } }
    @media only screen and (max-width: 768px) {
      .photos-grid.has-videos {
        width: 35%; } }
    @media only screen and (max-width: 480px) {
      .photos-grid.has-videos {
        width: 100%; } }
    @media only screen and (max-width: 768px) {
      .photos-grid.has-videos > *:nth-child(1) {
        display: none; } }
    @media only screen and (max-width: 480px) {
      .photos-grid.has-videos > *:nth-child(1) {
        display: none; } }
    .photos-grid.has-videos > *:nth-child(2) {
      margin-left: 0;
      margin-top: 20px;
      flex-grow: 1; }
      @media only screen and (max-width: 993px) {
        .photos-grid.has-videos > *:nth-child(2) {
          margin-top: 10px; } }
      @media only screen and (max-width: 768px) {
        .photos-grid.has-videos > *:nth-child(2) {
          margin-top: 0; } }
      @media only screen and (max-width: 480px) {
        .photos-grid.has-videos > *:nth-child(2) {
          margin-top: 10px; } }

@media only screen and (max-width: 768px) {
  .carousel-container-modal {
    margin-top: 100px; } }

.photos-item-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  font-size: 0;
  width: 100%; }
  @media only screen and (max-width: 768px) {
    .photos-item-image {
      width: 100%; } }
  @media only screen and (max-width: 480px) {
    .photos-item-image {
      width: 50%; } }
  .photos-item-image:after {
    content: '';
    background: #f1f2f3 url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNDNweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgNDMgMjYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQzIDI2IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBmaWxsPSIjQzlDRUQxIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3ICAgICIgZD0iTTQuNSw5QzcsOSw5LDcsOSw0LjVTNywwLDQuNSwwUzAsMiwwLDQuNVMyLDksNC41LDl6Ii8+PHBvbHlnb24gZmlsbD0iI0M5Q0VEMSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAgICAiIHBvaW50cz0iMjgsNiAyMSwxNyAxMywxMSA0LDI2IDQzLDI2ICIvPjwvc3ZnPg==) center no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
  .photos-item-image img {
    position: relative;
    z-index: -1;
    width: 100%;
    opacity: 0; }

.photos-item-more {
  font-size: 18px;
  position: relative; }
  .photos-item-more .photos-item-image {
    width: 100% !important; }
  .photos-item-more:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1; }

.photos-item-more-label {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff; }
  @media only screen and (max-width: 768px) {
    .photos-item-more-label {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center; } }

.photos-item-more-icon {
  margin-bottom: 10px; }

.image-full {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.carousel-container-modal :global(.slick-arrow) {
  width: 25px;
  height: 25px; }
  .carousel-container-modal :global(.slick-arrow):before {
    color: #fff;
    font-size: 26px; }
  @media only screen and (max-width: 768px) {
    .carousel-container-modal :global(.slick-arrow) {
      display: none !important; } }

.carousel-container-modal :global(.slick-next) {
  right: -40px; }

.carousel-container-modal :global(.slick-prev) {
  left: -40px; }

.clear-both {
  clear: both; }
