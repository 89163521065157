.markdown {
  padding-top: 15px;
  color: #696969;
  line-height: 25px; }
  .markdown a {
    color: var(--body-secondary-color);
    text-decoration: underline; }
  .markdown ul {
    padding: 10px 0;
    border-top: 3px solid var(--body-primary-color);
    column-count: 2;
    column-gap: 30px; }
    @media only screen and (max-width: 768px) {
      .markdown ul {
        column-count: 1; } }
  .markdown li {
    position: relative;
    padding: 5px 5px;
    break-inside: avoid;
    padding-left: 16px; }
    .markdown li:before {
      content: '\2022';
      position: absolute;
      left: 0;
      top: 4px;
      color: var(--body-primary-color);
      font-family: Montserrat-Bold, sans-serif;
      padding-right: 10px;
      float: left;
      font-size: 20px; }
    .markdown li ul {
      border: none;
      column-count: 1;
      padding: 0; }
      .markdown li ul li {
        position: relative;
        padding: 5px 5px; }
        .markdown li ul li:first-child {
          padding-top: 0; }
        .markdown li ul li:before {
          display: none; }
  .markdown > p {
    padding: 10px 0; }
