.loading-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .loading-indicator-container.small {
    min-height: 0; }
  .loading-indicator-container .loading-indicator {
    width: 30px;
    height: 30px;
    border: 3px solid transparent;
    border-top-color: var(--body-primary-color);
    border-right-color: var(--body-primary-color);
    border-radius: 50%;
    animation: load8 1s infinite linear; }
    .loading-indicator-container .loading-indicator.light {
      border-top-color: #fff;
      border-right-color: #fff; }
    .loading-indicator-container .loading-indicator.small {
      width: 25px;
      height: 25px; }
