.for-details {
  display: flex;
  border: 4px solid #f7f7f7;
  background-color: #f7f7f7;
  color: #696969;
  font-family: Montserrat-Medium, sans-serif; }
  .for-details li svg use {
    fill: var(--body-secondary-color);
    stroke: var(--body-secondary-color); }
  @media only screen and (max-width: 768px) {
    .for-details {
      display: block;
      border-width: 2px; } }
  .for-details > * + * {
    padding-left: 1rem; }
    @media only screen and (max-width: 768px) {
      .for-details > * + * {
        padding-left: 0; } }
  @media only screen and (max-width: 768px) {
    .for-details {
      padding: 12px; } }

.logo {
  width: 162px;
  padding: 10px;
  border-color: #e6e6e6;
  border-style: solid;
  border-right-width: 1px;
  background-color: #fff;
  flex-shrink: 0;
  display: flex;
  align-items: center; }
  .logo > img {
    width: 100%;
    height: 100%;
    object-fit: contain; }
  @media only screen and (max-width: 768px) {
    .logo {
      width: 103px;
      height: 103px;
      border-bottom-width: 1px;
      float: left;
      margin: -11px 10px 4px -11px; } }

.content-first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 20px 20px 20px 40px;
  align-items: flex-start; }
  .content-first h3 {
    font-size: 28px; }
    @media only screen and (max-width: 993px) {
      .content-first h3 {
        margin-bottom: 15px; } }
    @media only screen and (max-width: 768px) {
      .content-first h3 {
        min-height: 100px;
        font-size: 20px;
        margin-bottom: 10px; } }
  .content-first ul {
    order: -1; }
  @media only screen and (max-width: 768px) {
    .content-first {
      display: block;
      padding: 0; } }

.city-tag-duration {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0;
  padding: 20px 20px 20px 0; }
  @media only screen and (max-width: 993px) {
    .city-tag-duration {
      padding-right: 0; } }
  @media only screen and (max-width: 768px) {
    .city-tag-duration {
      padding: 0;
      flex-direction: row;
      justify-content: flex-start; } }

.phone-visible {
  display: none; }
  @media only screen and (max-width: 768px) {
    .phone-visible {
      display: inline-block; } }

@media only screen and (max-width: 768px) {
  .phone-hidden {
    display: flex;
    flex-wrap: wrap; } }

.apply-button-tablet {
  display: none; }
  @media only screen and (max-width: 993px) {
    .apply-button-tablet {
      display: inline-block; } }
  @media only screen and (max-width: 768px) {
    .apply-button-tablet {
      display: none; } }

.apply-wrapper {
  margin: auto;
  padding-right: 20px;
  padding-left: 0; }
  .apply-wrapper a {
    height: 56px;
    font-size: 18px;
    padding: 0 29px; }
    @media only screen and (max-width: 993px) {
      .apply-wrapper a {
        height: 40px;
        padding: 0 18px;
        font-size: 16px; } }
    @media only screen and (max-width: 768px) {
      .apply-wrapper a {
        margin-top: 10px; } }
  @media only screen and (max-width: 993px) {
    .apply-wrapper {
      display: none; } }
  @media only screen and (max-width: 768px) {
    .apply-wrapper {
      display: inline-block;
      padding-right: 0; } }
  @media only screen and (max-width: 480px) {
    .apply-wrapper {
      display: block;
      margin-top: 1rem; } }
