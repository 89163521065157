.alert-info {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background-color: #ebebf5;
  border: 1px solid var(--body-primary-color);
  font-size: 16px;
  display: flex;
  line-height: 20px; }
  .alert-info .alert-info-icon {
    color: var(--body-primary-color);
    margin-right: 10px; }
