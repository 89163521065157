.language-switcher {
  cursor: pointer;
  outline: none;
  transition: 0.3s;
  display: flex; }
  .language-switcher__menu {
    text-align: left; }
    .language-switcher__menu li {
      font-size: 16px;
      font-family: Montserrat-SemiBold, sans-serif;
      text-transform: uppercase;
      color: var(--header-text-color);
      padding: 5px 25px;
      cursor: pointer;
      transition: 0.3s; }
      @media only screen and (max-width: 993px) {
        .language-switcher__menu li {
          color: #707070; } }
      .language-switcher__menu li + li {
        border-top: 1px solid #cecece; }
      .language-switcher__menu li:hover {
        color: #333333; }

.icon {
  transition: 0.3s;
  margin-left: 15px; }

.icon__open {
  transform: rotate(180deg); }
