.is-blurred {
  position: relative; }
  @media only screen and (min-width: 768px) {
    .is-blurred {
      filter: blur(2px); }
      .is-blurred:before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background-color: rgba(255, 255, 255, 0.8);
        animation: main-show 0.1s; } }
