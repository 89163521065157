.ph-item {
  padding: 20px 0 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  opacity: 0.9; }
  .ph-item::before {
    animation-duration: 2s; }

.big {
  height: 20px; }

.small {
  height: 10px; }

.ph-picture {
  margin: 0; }

.ph-avatar {
  width: 80%;
  margin-bottom: 0;
  min-width: 103px; }
  @media only screen and (max-width: 768px) {
    .ph-avatar {
      margin-bottom: 10px; } }

[class*='ph-col'] {
  margin: 0 !important; }
  @media only screen and (max-width: 768px) {
    [class*='ph-col'] {
      flex-grow: 1; } }

.media-phone-button {
  margin: 10px 0 !important; }
  @media only screen and (max-width: 768px) {
    .media-phone-button .ph-row {
      flex-wrap: nowrap;
      flex-direction: column;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end; }
      .media-phone-button .ph-row .ph-col-12.big {
        width: 200px; } }
    @media only screen and (max-width: 768px) and (max-width: 480px) {
      .media-phone-button .ph-row .ph-col-12.big {
        width: 100%; } }

.media-photo-group .ph-col-4 {
  margin-bottom: 10px !important; }
  @media only screen and (max-width: 993px) {
    .media-photo-group .ph-col-4 {
      flex: 0 0 50%; } }
  @media only screen and (max-width: 480px) {
    .media-photo-group .ph-col-4 {
      flex: 0 0 100%; } }
