.apply-btn {
  min-width: 0 !important;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;
  display: flex;
  justify-content: space-between !important;
  align-items: center; }

.apply-btn-icon {
  margin-left: 20px; }
  .apply-btn-icon use {
    stroke: #fff; }
