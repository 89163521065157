.form-input {
  display: block;
  width: 100%;
  color: #525252;
  background-color: transparent;
  background-clip: padding-box;
  padding: 7px 12px;
  border: 1px solid #cecece;
  border-radius: 5px; }
  .form-input:focus {
    outline: none; }
  .form-input::placeholder {
    color: #b5b5b5;
    opacity: 1; }
  .form-input:disabled, .form-input[readonly] {
    background-color: #b5b5b5;
    opacity: 1; }
