.dropdown {
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  order: 0; }

.modal {
  display: flex;
  align-items: center; }

.dropdown-control {
  background-color: transparent;
  border-radius: 3px;
  color: var(--body-secondary-color);
  border: 1px solid var(--body-secondary-color);
  padding: 8px 10px;
  position: relative;
  overflow: hidden;
  outline: none;
  transition: all 200ms ease;
  font-size: 14px;
  display: flex;
  align-items: center; }
  .dropdown-control.active {
    background-color: var(--body-secondary-color);
    color: #fff;
    border-color: transparent; }

.dropdown-header {
  flex: auto;
  white-space: nowrap; }

.dropdown-arrow {
  margin-left: 5px; }

.is-open .dropdown-arrow {
  transform: rotate(180deg); }

@keyframes zoom {
  0% {
    transform: scale(1);
    order: 0; }
  50% {
    transform: scale(0.5);
    display: none; }
  100% {
    transform: scale(1);
    display: none; } }

.dropdown-wrapper {
  margin-top: 2px;
  position: absolute;
  top: 100%;
  min-width: 320px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-height: 320px;
  border: 1px solid var(--body-border-color);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px; }
  .dropdown-wrapper:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    height: 20px;
    background-color: #fff;
    z-index: 1; }
  @media only screen and (max-width: 768px) {
    .dropdown-wrapper {
      position: static;
      top: inherit;
      max-height: inherit;
      flex-grow: 1;
      display: flex;
      width: 100%; } }

.dropdown-container {
  background-color: #fff;
  padding: 20px 20px 10px;
  overflow-y: auto;
  max-height: 255px;
  /* Track */
  /* Handle */ }
  .dropdown-container::-webkit-scrollbar {
    width: 5px; }
  .dropdown-container::-webkit-scrollbar-track {
    background: #d0d0d0; }
  .dropdown-container::-webkit-scrollbar-thumb {
    background: var(--body-primary-color)80%; }
  +
.dropdown-container::-webkit-scrollbar-thumb:hover {
    background: var(--body-primary-color); }
  .dropdown-container div ::-webkit-scrollbar {
    width: 5px; }
  .dropdown-container div ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .dropdown-container div ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey; }
  .dropdown-container div::placeholder {
    color: #000; }
  @media only screen and (max-width: 768px) {
    .dropdown-container {
      border: none;
      max-height: none;
      flex-grow: 1; } }
  .dropdown-container input {
    padding-left: 10px;
    display: flex;
    align-items: center; }
  @media (min-width: 0) and (max-width: 479px) {
    .dropdown-container {
      position: relative;
      max-height: 100%; } }
  .dropdown-container::-webkit-scrollbar {
    width: 4px; }
  .dropdown-container::-webkit-scrollbar-track {
    margin: 2px; }

@keyframes show {
  0% {
    max-height: 0; }
  70% {
    max-height: 0; }
  100% {
    max-height: 330px; } }

.dropdown-container-buttons {
  border-top: 1px solid var(--body-border-color);
  padding: 10px 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 768px) {
    .dropdown-container-buttons {
      border-top: 1px solid #e6e6e6;
      background-color: #fff;
      padding: 15px 20px; } }

button.dropdown-container-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  text-decoration: none;
  transition: 0.3s;
  font-family: Montserrat-Medium, sans-serif;
  text-transform: capitalize;
  background-color: #fff; }
  @media only screen and (max-width: 768px) {
    button.dropdown-container-button span {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: transparent;
      padding: 0; } }
  button.dropdown-container-button img {
    width: 30px;
    filter: invert(100); }
  button.dropdown-container-button:last-child {
    color: #183a64; }
    @media only screen and (max-width: 768px) {
      button.dropdown-container-button:last-child {
        border-radius: 5px;
        height: 45px;
        transition: 0.3s; }
        button.dropdown-container-button:last-child:hover {
          opacity: 0.8; }
        button.dropdown-container-button:last-child span {
          margin-left: 5px; } }
