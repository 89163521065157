.marker {
  width: 50px;
  height: 50px;
  border-radius: 50% 50% 50% 0;
  background-color: #89849b;
  transform: rotate(-45deg);
  margin: -25px 0 0 -25px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
  cursor: pointer; }
  .marker.primary {
    background-color: var(--body-primary-color); }
  .marker.multi {
    margin: -50px 0 0 -50px; }

.circle {
  width: 40px;
  height: 40px;
  margin: 5px 0 0 5px;
  position: absolute;
  border-radius: 50%;
  transform: rotate(45deg);
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  .circle.label {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }

.tooltip {
  max-width: 400px !important; }

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg); }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg); }
  80% {
    transform: translateY(-10px) rotate(-45deg); }
  100% {
    transform: translateY(0) rotate(-45deg); } }
