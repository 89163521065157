.alert-error {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background-color: #ebebf5;
  border: 1px solid #ff0500; }
  .alert-error .alert-error-icon {
    color: #ff0500;
    margin-right: 10px; }
