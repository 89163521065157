.rating {
  display: inline-block;
  margin-left: 10px; }

.back-stars {
  color: #ffe7ac;
  position: relative;
  display: flex; }
  .back-stars use {
    fill: #ffe7ac; }
  .back-stars > div {
    margin-right: 4px; }

.front-stars {
  color: #fec230;
  overflow: hidden;
  position: absolute;
  display: flex;
  top: 0; }
  .front-stars use {
    fill: #fec230; }
  .front-stars > div {
    margin-right: 4px; }
