.check-input-container {
  position: relative;
  display: block;
  padding-left: 1.25rem; }
  .check-input-container .check-input {
    position: absolute;
    margin-top: 2px;
    margin-left: -1.25rem; }
    .check-input-container .check-input:disabled ~ .check-input-label {
      color: #b5b5b5; }
  .check-input-container .check-input-label {
    margin-bottom: 0; }
    .check-input-container .check-input-label.small {
      font-size: 11px;
      padding-top: 5px; }
  .check-input-container.check-input-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
    .check-input-container.check-input-inline .check-input {
      position: static;
      margin-top: 0;
      margin-right: 0.3125rem;
      margin-left: 0; }
