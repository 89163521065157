.loader {
  position: relative;
  min-height: 50px;
  width: 100%; }
  .loader .loading-content.hidden {
    opacity: 0.2; }
  .loader.full {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
