.checkbox {
  display: none; }
  .checkbox:checked ~ .checkbox-status {
    background-color: var(--body-secondary-color);
    border-color: var(--body-secondary-color); }

.check-box {
  color: #fff;
  font-size: 12px; }

.small-checkbox {
  width: 14px !important;
  height: 14px !important; }

.checkbox-status {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #a0a0a0;
  transition: 0.2s;
  border-radius: 2px;
  margin-right: 10px;
  flex-shrink: 0; }

.checkbox-label {
  justify-content: flex-end;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 10px 0;
  color: #696969;
  cursor: pointer; }
  .checkbox-label:first-child {
    margin-top: 0; }

.checkbox-icon {
  color: #cecece;
  font-size: 20px;
  margin-right: 5px; }
  .checkbox-icon.checked {
    color: var(--body-primary-color); }
