.call-body {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(24, 58, 100, 0.05) 100%); }
  .call-body__vacancy {
    margin: 50px 0 90px; }
    @media only screen and (max-width: 768px) {
      .call-body__vacancy {
        margin: 50px 0; } }
  .call-body__inner {
    position: relative; }
  .call-body__container {
    position: absolute;
    max-width: 440px;
    width: 50%;
    z-index: 100;
    bottom: -53px;
    left: 100px; }
    @media only screen and (max-width: 993px) {
      .call-body__container {
        left: 40px;
        width: 50%; } }
    @media only screen and (max-width: 768px) {
      .call-body__container {
        display: block;
        position: relative;
        width: 100%;
        max-width: 100%;
        left: 0;
        bottom: 0; } }
  .call-body__button {
    padding: 0 28px;
    height: 55px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    color: #fff; }
    .call-body__button a {
      color: inherit; }
    .call-body__button--green {
      background-color: #e95160; }
      .call-body__button--green:hover {
        background-color: #e32437; }
    .call-body__button--red {
      background-color: #17d428; }
      .call-body__button--red:hover {
        background-color: #12a61f; }
  .call-body__svg {
    font-size: 33px;
    margin-right: 23px;
    cursor: pointer; }
  .call-body__phone {
    width: 30px;
    height: 30px;
    font-size: 53px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 18px; }
  .call-body__form {
    font-family: Montserrat-SemiBold, sans-serif;
    color: #fff;
    font-size: 18px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .call-body__form {
        position: relative; } }
  .call-body__box {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.22);
    border-radius: 3px;
    background-color: #183a64;
    padding: 34px; }
    @media only screen and (max-width: 480px) {
      .call-body__box {
        padding: 15px; } }
  .call-body__text {
    margin-bottom: 40px; }
    @media only screen and (max-width: 480px) {
      .call-body__text {
        margin-bottom: 15px; } }
  .call-body__title {
    font-family: Montserrat-Bold-Italic, sans-serif;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 440px;
    left: 100px;
    text-align: center; }
    .call-body__title img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 40% !important; }
    @media only screen and (max-width: 993px) {
      .call-body__title {
        width: 50% !important;
        left: 40px; } }
    @media only screen and (max-width: 480px) {
      .call-body__title {
        left: 30px; } }
  .call-body__slider {
    position: relative;
    z-index: 99;
    font-size: 0; }
  .call-body__item {
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    position: relative;
    background-repeat: no-repeat;
    background-position: 15% 10%;
    background-size: 10%; }
  .call-body__info {
    position: absolute;
    width: 50%;
    bottom: 0;
    right: 0;
    font-family: Montserrat-Medium, sans-serif;
    text-align: center;
    padding-bottom: 15px;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.62);
    color: #183a64;
    font-size: 18px; }
    @media only screen and (max-width: 768px) {
      .call-body__info {
        font-size: 14px; } }
  .call-body__avatar {
    position: relative;
    font-size: 0;
    width: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center; }
    .call-body__avatar > img {
      width: 100%; }
