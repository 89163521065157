.container {
  position: relative;
  flex: 1; }

.suggestionsContainer {
  display: none; }

.suggestionsContainerOpen {
  display: block;
  position: absolute;
  top: 53px;
  width: 100%;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 2;
  max-height: 300px;
  overflow-y: auto; }
  .suggestionsContainerOpen::-webkit-scrollbar-thumb {
    background-color: #696969; }

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.suggestion {
  cursor: pointer;
  padding: 10px 20px; }

.suggestionHighlighted {
  background-color: #e6e6e6; }
