.videos {
  margin-right: 20px;
  width: calc(45% - 20px);
  float: left;
  flex-shrink: 0; }
  .videos.single-photo {
    width: calc(37.7% - 20px); }
    @media only screen and (max-width: 480px) {
      .videos.single-photo {
        width: 100%; } }
  @media only screen and (max-width: 993px) {
    .videos {
      width: calc(44.6% - 10px);
      margin-right: 10px; } }
  @media only screen and (max-width: 768px) {
    .videos {
      width: calc(65% - 10px); } }
  @media only screen and (max-width: 480px) {
    .videos {
      margin-right: 0;
      width: 100%;
      float: none; } }

.outline-none {
  outline: none; }

.video-preview {
  position: relative; }
  .video-preview :global(.video-js.vjs-fluid) {
    padding-top: 100%; }

.more-videos {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)); }

.more-videos-icon {
  margin-right: 5px; }

.video-details {
  color: #fff;
  font-family: Montserrat-Bold, sans-serif;
  position: absolute;
  left: 18px;
  right: 18px;
  bottom: 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }

.video-logo {
  width: 80px; }

.video-title {
  font-size: 28px;
  margin-top: 10px; }
  @media only screen and (max-width: 768px) {
    .video-title {
      font-size: 18px; } }

.video-duration {
  background-color: #151515;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.39);
  font-size: 20px;
  opacity: 0.67;
  padding: 0.5rem 1rem;
  margin-left: 10px; }
  @media only screen and (max-width: 768px) {
    .video-duration {
      font-size: 14px; } }
