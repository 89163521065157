.inline-filter {
  padding: 10px 0;
  border-bottom: 1px solid var(--body-border-color);
  position: relative;
  z-index: 100;
  min-height: 53px;
  display: none;
  align-items: center; }
  @media only screen and (max-width: 480px) {
    .inline-filter {
      display: block; } }
  .inline-filter__box {
    width: 100%;
    max-width: 1980px;
    height: 100%;
    margin: auto;
    position: relative; }
  .inline-filter__wrapper {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    flex-grow: 1; }
    @media only screen and (max-width: 1270px) {
      .inline-filter__wrapper {
        transform: none;
        position: static; } }
    @media only screen and (max-width: 768px) {
      .inline-filter__wrapper {
        overflow-x: auto; } }
  .inline-filter__fake-logo {
    margin-right: 30px;
    margin-left: 20px;
    opacity: 0;
    height: 40px; }
    .inline-filter__fake-logo img {
      height: 100%; }
    @media only screen and (max-width: 1270px) {
      .inline-filter__fake-logo {
        display: none; } }
  .inline-filter__container {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end; }
    @media only screen and (max-width: 1270px) {
      .inline-filter__container {
        justify-content: space-between; } }
  .inline-filter__tabs {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin: 0 -5px; }
    .inline-filter__tabs > div {
      margin: 0 5px; }
  .inline-filter__select {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 10px 0; }
    @media only screen and (max-width: 768px) {
      .inline-filter__select span {
        padding: 0; } }
