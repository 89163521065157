.top-job-header {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--body-border-color);
  border-radius: 5px;
  font-size: 18px;
  font-family: Montserrat-Medium, sans-serif; }
  @media only screen and (max-width: 993px) {
    .top-job-header {
      font-size: 14px; } }
  @media only screen and (max-width: 768px) {
    .top-job-header {
      display: block; } }

.top-job-image {
  flex-basis: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 250px;
  position: relative;
  border-radius: 5px 0 0 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16); }
  @media only screen and (max-width: 993px) {
    .top-job-image {
      flex-basis: 40%; } }
  @media only screen and (max-width: 768px) {
    .top-job-image {
      min-height: 350px;
      border-radius: 5px 5px 0 0;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      margin-right: 0; } }
  @media only screen and (max-width: 480px) {
    .top-job-image {
      min-height: 172px; } }
  .top-job-image > img {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 72px; }

.top-job-body {
  flex-basis: 50%;
  padding: 6px 40px 25px 44px; }
  @media only screen and (max-width: 993px) {
    .top-job-body {
      flex-basis: 60%;
      padding: 30px 20px 30px 30px; } }
  @media only screen and (max-width: 768px) {
    .top-job-body {
      padding: 12px; } }
  .top-job-body h3 {
    font-size: 28px; }
    @media only screen and (max-width: 993px) {
      .top-job-body h3 {
        margin-top: 10px;
        margin-bottom: 12px; } }
    @media only screen and (max-width: 480px) {
      .top-job-body h3 {
        font-size: 20px; } }

.top-job {
  color: #333333;
  margin-bottom: 37px;
  display: flex;
  align-items: center;
  font-size: 18px; }
  @media only screen and (max-width: 480px) {
    .top-job {
      margin-bottom: 15px; } }

.top-job-apply-wrapper {
  display: flex;
  justify-content: flex-end; }
  .top-job-apply-wrapper > a {
    height: 56px;
    margin-top: 32px;
    font-size: 18px;
    padding: 0 29px; }
    @media only screen and (max-width: 993px) {
      .top-job-apply-wrapper > a {
        height: 45px; } }
    @media only screen and (max-width: 768px) {
      .top-job-apply-wrapper > a {
        padding: 0 18px; } }
  @media only screen and (max-width: 480px) {
    .top-job-apply-wrapper {
      display: block; } }
