.header-narrow .header__nav {
  position: relative; }

.header {
  font-family: Montserrat-SemiBold, sans-serif;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 0;
  background-image: var(--header-bg-image);
  min-height: 800px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1; }
  .header__nav {
    color: var(--body-secondary-color);
    font-family: Montserrat-Medium, sans-serif; }
  .header .logo-search {
    display: none; }
  .header-narrow {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0;
    background-image: var(--header-bg-image);
    background-color: #fff;
    position: relative;
    width: 100%;
    top: 0;
    z-index: 1000; }
    .header-narrow .logo-main {
      display: none; }
    @media only screen and (max-width: 480px) {
      .header-narrow {
        top: 0;
        position: sticky;
        overflow-scrolling: touch;
        backface-visibility: hidden; } }
    .header-narrow .logo {
      height: 40px;
      transition: 0.3s; }
    @media only screen and (max-width: 480px) {
      .header-narrow .header__inner .logo-null {
        max-width: 0;
        overflow: hidden;
        margin-right: 0;
        animation: changeWidth 0.3s; } }

@keyframes changeWidth {
  from {
    max-width: 200px; }
  to {
    max-width: 0; } }
    .header-narrow .logo-search {
      display: block; }
    .header-narrow .header__nav {
      padding: 10px 20px; }
  @media only screen and (max-width: 768px) {
    .header .logo {
      height: 40px; } }
  @media only screen and (max-width: 480px) {
    .header {
      min-height: auto;
      height: auto;
      background-position: center 0;
      -webkit-background-size: contain;
      background-size: 200%; }
      .header:before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 0;
        background-color: rgba(255, 255, 255, 0.3); }
      .header .logo {
        width: 100px;
        margin-right: 30px;
        flex-shrink: 0; } }
  .header__balance {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative; }
  .header__wrapper {
    height: 100%;
    max-width: 1170px;
    margin: auto; }
  .header__filter {
    max-width: 426px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    background-color: #ffffff;
    padding: 33px 42px 40px 42px;
    margin-left: 100px;
    font-size: 16px; }
    @media only screen and (max-width: 993px) {
      .header__filter {
        margin-left: 55px; } }
    @media only screen and (max-width: 480px) {
      .header__filter {
        padding: 0;
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
        position: relative;
        z-index: 1;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%; }
        .header__filter .header__filter--title {
          padding: 0 20px;
          display: flex;
          align-items: flex-end;
          min-height: 350px; }
        .header__filter form {
          padding: 20px 12px;
          background-color: #fff; } }
    .header__filter--title {
      margin-bottom: 20px; }
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 480px) {
      .header__inner {
        margin: 0 -10px; } }
    .header__inner .logo {
      margin-right: 30px; }
      @media only screen and (max-width: 480px) {
        .header__inner .logo {
          margin-right: 15px; } }
  .header__nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 17px 20px;
    z-index: 1;
    max-width: 1980px;
    margin: auto; }
    @media only screen and (max-width: 480px) {
      .header__nav {
        padding: 10px 20px; } }
  .header__box {
    height: 100%;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 480px) {
      .header__box {
        z-index: 0;
        display: block; } }
  .header__content {
    flex-grow: 1;
    border-top: 1px solid #e6e6e6;
    position: relative;
    z-index: 0; }
  .header__links {
    display: flex;
    align-items: center;
    flex-shrink: 0; }
  .header-link--space {
    margin-left: 49px; }
  @media only screen and (max-width: 993px) {
    .header-link--lang {
      margin-top: 20px; } }
  .header__center {
    flex-grow: 1;
    max-width: 450px;
    position: relative;
    z-index: 1; }
    @media only screen and (max-width: 768px) {
      .header__center {
        /*      position: absolute;
      right: 0;*/ } }

.field-space {
  margin-bottom: 14px; }

.change-menu__open {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10; }
  @media only screen and (max-width: 993px) {
    .change-menu__open {
      overflow: hidden;
      width: 100%;
      display: block;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      background-color: transparent;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      max-height: 80px;
      padding: 0 15px 36px; } }

.change-menu__box {
  display: flex;
  transition: 0.3s;
  align-items: center; }
  @media only screen and (max-width: 993px) {
    .change-menu__box {
      padding-top: 82px;
      flex-direction: column;
      align-items: flex-end; } }

@media only screen and (max-width: 993px) {
  .change-menu__link {
    margin-bottom: 20px; } }

.change-menu__buttons {
  position: relative;
  z-index: 1; }

.change-menu__arrow {
  padding: 0 12px;
  font-size: 15px;
  color: #505050;
  transition: 0.3s;
  display: none; }
  @media only screen and (max-width: 480px) {
    .change-menu__arrow {
      display: block; } }

.change-menu__button {
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 17px;
  display: none;
  margin-left: 25px;
  z-index: 1; }
  @media only screen and (max-width: 993px) {
    .change-menu__button {
      display: block; } }
  @media only screen and (max-width: 480px) {
    .change-menu__button {
      display: none;
      margin-left: 0; } }
  .change-menu__button span {
    position: absolute;
    display: none;
    width: 31px;
    height: 3px;
    background-color: #183a64;
    opacity: 1;
    transition: 0.3s; }
    @media only screen and (max-width: 993px) {
      .change-menu__button span {
        display: block; } }
    .change-menu__button span:nth-child(1) {
      top: 0; }
    .change-menu__button span:nth-child(2) {
      top: 7px; }
    .change-menu__button span:nth-child(3) {
      bottom: 0; }
    @media only screen and (max-width: 480px) {
      .change-menu__button span {
        display: none; }
        .change-menu__button span:nth-child(1) {
          width: 13px;
          left: 6px;
          top: 6px;
          transform: rotate(45deg); }
        .change-menu__button span:nth-child(2) {
          opacity: 0; }
        .change-menu__button span:nth-child(3) {
          width: 13px;
          right: 6px;
          bottom: 8px;
          transform: rotate(-45deg); } }

.change-menu.show {
  z-index: 1; }
  .change-menu.show .change-menu__arrow {
    transform: rotate(180deg); }
  .change-menu.show .change-menu__button span:nth-child(1) {
    width: 31px;
    left: 1px;
    top: 6px;
    transform: rotate(-45deg); }
  .change-menu.show .change-menu__button span:nth-child(2) {
    opacity: 0; }
  .change-menu.show .change-menu__button span:nth-child(3) {
    width: 31px;
    right: 1px;
    bottom: 8px;
    transform: rotate(45deg); }
  @media only screen and (max-width: 993px) {
    .change-menu.show .change-menu__open {
      overflow-y: auto;
      transition: max-height 0.4s ease-in-out;
      background-color: #fff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      max-height: 300px; } }
