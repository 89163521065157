.partner {
  margin: 10px 0; }

.partner-block {
  display: flex;
  align-items: center;
  font-family: Montserrat-Bold, sans-serif;
  justify-content: space-between;
  color: #333333;
  flex-wrap: wrap; }
  @media only screen and (max-width: 480px) {
    .partner-block img {
      margin: 10px 0; } }

.partner-link {
  font-family: Montserrat-Regular, sans-serif;
  color: var(--body-secondary-color);
  text-decoration: underline; }

.partner-logo {
  height: 50px; }

.partner-description {
  margin-top: 10px;
  color: #696969;
  line-height: 20px; }
