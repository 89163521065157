.footer {
  background-color: #161616;
  font-size: 13px; }

.footer__copyright {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: grey;
  font-size: 14px;
  padding: 10px;
  background-color: #161616; }
  .footer__copyright a {
    color: #e95160; }
