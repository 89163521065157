.vacancy-photo-video {
  margin-top: 20px;
  display: flex;
  align-items: flex-start; }
  @media only screen and (max-width: 480px) {
    .vacancy-photo-video {
      display: block; } }

.vacancy-body-block {
  padding-top: 65px;
  clear: both; }
  @media only screen and (max-width: 993px) {
    .vacancy-body-block {
      padding-top: 58px; } }
  @media only screen and (max-width: 480px) {
    .vacancy-body-block {
      padding-top: 40px; } }

.vacancy-body-block-title {
  color: #333333;
  margin-bottom: 20px; }

.vacancy-body-map {
  height: 466px; }
  @media only screen and (max-width: 480px) {
    .vacancy-body-map {
      height: 266px; } }
